<template>
  <div>
    <p class="display-1 mt-2 mb-5 text-uppercase">
      {{ $t('title-vessel-schedules') }}
    </p>

    <VesselScheduleSearch request-open-in-new-tab />

    <!-- <v-row no-gutters>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 8"
        :class="{
          'pr-2': !$vuetify.breakpoint.mobile,
        }"
        :order="$vuetify.breakpoint.mobile ? 2 : 1"
      >
        <VesselScheduleSearchResultList />
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 4"
        :order="$vuetify.breakpoint.mobile ? 1 : 2"
      >
        <VesselScheduleSearchFilter
          :class="{
            sticky: !$vuetify.breakpoint.mobile,
            'mb-2': $vuetify.breakpoint.mobile,
          }"
        />
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import VesselScheduleList from './VesselScheduleList.vue';
import VesselScheduleListFilter from './VesselScheduleListFilter.vue';
import VesselScheduleSearch from '@/components/shared/local/vsl/VesselScheduleSearch';
import VesselScheduleSearchFilter from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchFilter.vue';
import VesselScheduleSearchResultList from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchResultList.vue';
export default {
  components: {
    VesselScheduleList,
    VesselScheduleListFilter,
    VesselScheduleSearch,
    VesselScheduleSearchFilter,
    VesselScheduleSearchResultList,
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 16px;
}
</style>
